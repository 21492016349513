import React, { useState } from "react";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function Loader({ loading, color = "blue" }) {
  return (
    <div className="Loader">
      <div className="sweet-loading">
        <ScaleLoader
          color={color}
          loading={loading}
          css={override}
          size={300}
        />
      </div>
    </div>
  );
}

export default Loader;
