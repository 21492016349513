import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ic_options from "../assets/images/Trasnvehicle/ic_options.svg";
import dropdown_arrow_blue from "../assets/images/dropdown_arrow_blue.svg";
import { Modal } from "react-bootstrap";
import ic_vehicle from "../assets/images/AddVehicles/ic_vehicle.svg";
import circle_cross from "../assets/images/circle_cross.svg";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../context/user/AppContext";
import {
  PostCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import swal from "sweetalert";

const OfferVehicles = () => {
  const { mainClass, customerData } = useContext(AppContext);
  const navigate = useNavigate();
  const data = useLocation().state;
  const [Load, setLoad] = useState({ ...data });
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [selectedPlateno, setSelectedplateno] = useState("");
  const [vehicleDetails, setVehicleDetails] = useState({
    truck_id: "",
    trailer_id: "",
    driver_id: "",
    bid_id: (Load?.bidres && Load?.bidres) || "",
  });

  $(document).ready(function () {
    $(".datalist").DataTable();
  });
  const [selectVehicle, setSelectedVehicle] = useState({ vehicle_images: [] });
  console.log("selectVehicle", selectVehicle);
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [driverList, setDriverList] = useState([]);
  const [trailerList, setTrailerList] = useState([]);
  const [vehicle_name_id, setVehicle_name_id] = useState("");
  const [selectedtrailer, setSelectedtrailer] = useState({
    vehicle_images: [],
  });
  const [checkTrailer, setCheckTrailer] = useState([]);
  const [plateNoList, setPlateNoList] = useState([]);
  const [addedVehicleList, setAddedVehicleList] = useState([]);
  const handleOfferVehicle = () => {
    setShow(true);
    setSelectedDriver([]);
    setSelectedVehicle({});
    setSelectedtrailer({});
    setCheckTrailer([]);
    setVehicleDetails({ ...vehicleDetails, driver_id: "" });
    getPowerPlateNoList();
  };
  useEffect(() => {
    if (Load?.auction_details) {
      getBidList();
    }
    setSelectedVehicle(selectVehicle);
    getAddVehicleList();
    getDriverList();
    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.TRUCK_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        const data = res?.json?.vehicle_types;
        const filteredTruckList = data.filter((truck) => {
          return !addedVehicleList.some(
            (vehicle) => vehicle.vehicle_id == truck.vehicle_name_id
          );
        });
        setVehicleList(filteredTruckList);
      })
      .catch((err) => console.log(err));
    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.TRAILER_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        const data = res?.json?.vehicle_list;
        const filteredTrailerList = data.filter((trailer) => {
          return !addedVehicleList.some(
            (vehicle) => vehicle.trailer_id == trailer.vehicle_id
          );
        });
        setTrailerList(filteredTrailerList);
      })
      .catch((err) => console.log(err));
  }, [show, selectedVehicleType]);

  const setCurrentPlateNo = (e) => {
    let vehicle = plateNoList.filter(
      (vehicle) => vehicle.vehicle_id == e?.target?.value
    )[0];

    setSelectedVehicle({
      ...vehicle,
      load_reference_no: Load && Load.load_reference_no,
    });
    setVehicleDetails({
      ...vehicleDetails,
      truck_id: vehicle?.vehicle_id,
      // trailer_id: trailer?.vehicle_id,
    });
    if (selectVehicle?.vehicle_type === "Truck Trailer") {
      setVehicleDetails({ ...vehicleDetails, trailer_id: null });
    }
  };
  const setCurrentVehicle = (e) => {
    setSelectedVehicle({});
    setSelectedVehicleType(e?.target?.value);
    setVehicleDetails({ ...vehicleDetails, trailer_id: "", truck_id: "" });
    const selectedId = e.target.options[e.target.selectedIndex].id;
    let vehicle = vehicleList.filter(
      (vehicle) => vehicle.vehicle_name_id == selectedId,
      setVehicle_name_id(selectedId)
    );
  };
  const setCurrenttrailer = (e) => {
    let trailer = trailerList.filter(
      (vehicle) => vehicle.vehicle_id == e?.target?.value
    )[0];
    setCheckTrailer([{ ...trailer }]);
    setSelectedtrailer({
      ...trailer,
      load_reference_no: Load && Load.load_reference_no,
    });
    setVehicleDetails({
      ...vehicleDetails,
      // truck_id: vehicle?.vehicle_id,
      trailer_id: trailer?.vehicle_id,
    });
  };
  const setCurrentDriver = (e) => {
    let driver = driverList.filter(
      (driver) => driver.driver_id == e.target.value
    )[0];
    setSelectedDriver(driver);
    setVehicleDetails({ ...vehicleDetails, driver_id: driver.driver_id });
  };

  const getBidList = () => {
    // setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.BIDLIST,
      JSON.stringify({ ...customerData, load_id: Load?.trip_id })
    )
      .then((res) => {
        if (res.json.result) {
          res.json.bid_list.map((item) => {
            setVehicleDetails({ ...vehicleDetails, bid_id: item.bid_id });
          });
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };
  const getPowerPlateNoList = () => {
    // setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.POWER_PLATE_NO,
      JSON.stringify({ ...customerData, vehicle_name_id: vehicle_name_id })
    )
      .then((res) => {
        if (res.json.result) {
          const data = res?.json?.vehicle_list;
          const filteredPlateNumberList = data.filter((plate) => {
            return !addedVehicleList.some(
              (vehicle) => vehicle.vehicle_id == plate.vehicle_id
            );
          });
          // const filteredArray = dataArray.filter((item) => item.plate_number !== plateNumberToRemove);
          setPlateNoList(filteredPlateNumberList);
          setVehicleDetails({
            ...vehicleDetails,
            truck_id: filteredPlateNumberList[0]?.vehicle_id,
          });
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };
  const getDriverList = () => {
    // setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.ACTIVE_DRIVER_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.result) {
          const data = res?.json?.driver_list;
          const filteredDriverList = data.filter((driver) => {
            return !addedVehicleList.some(
              (vehicle) => vehicle.driver_id === driver.driver_id
            );
          });
          setDriverList(filteredDriverList);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };
  const getAddVehicleList = async () => {
    // setLoading(true);
    if (Load?.auction_details) {
      await postWithAuthCallWithErrorResponse(
        ApiConfig.ONLINE_LISTVEHICLEOFFER,
        JSON.stringify({
          ...customerData,
          reference_no: Load.load_reference_no,
          bid_id: vehicleDetails?.bid_id,
        })
      )
        .then((res) => {
          // setLoading(false);
          if (res.json.result) {
            setAddedVehicleList(res.json.vehicle_list);
          }
        })
        .catch((err) => {
          console.log("Ex :: ", err);
        });
    } else {
      postWithAuthCallWithErrorResponse(
        ApiConfig.LISTVEHICLEOFFER,
        JSON.stringify({
          ...customerData,
          reference_no: Load.load_reference_no,
        })
      )
        .then((res) => {
          // setLoading(false);
          if (res.json.result) {
            setAddedVehicleList(res.json.vehicle_list);
          }
        })
        .catch((err) => {
          console.log("Ex :: ", err);
        });
    }
  };

  const addVehicleOffer = () => {
    if (Load?.auction_details) {
      let online_details = JSON.stringify({
        customer_id: customerData.customer_id,
        api_key: customerData.api_key,
        user_id: customerData.user_id,
        truck_id: vehicleDetails?.truck_id,
        trailer_id: Number(vehicleDetails?.trailer_id)
          ? Number(vehicleDetails?.trailer_id)
          : "",
        driver_id: vehicleDetails?.driver_id,
        reference_no: Load?.load_reference_no,
        bid_id: vehicleDetails?.bid_id,
      });
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.ADD_ONLINE_VEHICLEOFFER,
        online_details
      )
        .then((res) => {
          swal(res.json.message);
          setShow(false);
          getAddVehicleList();
        })
        .catch((err) => console.log(err));
    } else {
      let details = JSON.stringify({
        customer_id: customerData.customer_id,
        api_key: customerData.api_key,
        user_id: customerData.user_id,
        truck_id: vehicleDetails?.truck_id,
        trailer_id: Number(vehicleDetails?.trailer_id)
          ? Number(vehicleDetails?.trailer_id)
          : "",
        driver_id: vehicleDetails?.driver_id,
        reference_no: Load?.load_reference_no,
      });
      if (vehicleDetails?.driver_id) {
        postMultipartWithAuthCallWithErrorResponse(
          ApiConfig.ADDVEHICLEOFFER,
          details
        )
          .then((res) => {
            swal(res.json.message);
            setShow(false);
            getAddVehicleList();
          })
          .catch((err) => console.log(err));
      } else {
        swal("Driver for these operation is not avilable ");
      }
    }
  };
  const sendVehicleOffer = () => {
    let details = JSON.stringify({
      customer_id: customerData.customer_id,
      api_key: customerData.api_key,
      user_id: customerData.user_id,
      reference_no: Load?.load_reference_no,
    });
    if (Load?.auction_details) {
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.ONLINE_SENDVEHICLEOFFER,
        details
      )
        .then((res) => {
          swal(res.json.message);
          navigate("/OfferedVehiclesOnline");
        })
        .catch((err) => console.log(err));
    } else {
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.SENDVEHICLEOFFER,
        details
      )
        .then((res) => {
          swal(res.json.message);
          navigate("/OfferedVehicles");
        })
        .catch((err) => console.log(err));
    }
  };

  const deleteVehicleOffer = (id, bidId) => {
    if (id) {
      postWithAuthCallWithErrorResponse(
        ApiConfig.DELETE_VEHICLE_OFFER,
        JSON.stringify({
          ...customerData,
          trip_vehicle_id: id,
        })
      )
        .then((res) => {
          swal(res.json.message);
          navigate("/OfferGoodsDirect/");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      postWithAuthCallWithErrorResponse(
        ApiConfig.DELETE_BID_VEHICLE_OFFER,
        JSON.stringify({
          ...customerData,
          bid_vehicle_id: bidId,
        })
      )
        .then((res) => {
          swal(res.json.message);
          navigate("/VehicleRequestOnlineAuction/");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (vehicle_name_id) {
      getPowerPlateNoList();
    }
  }, [vehicle_name_id]);

  return (
    <>
      <main className={"main-offerVehicles " + mainClass}>
        <div className="btn-offer-vehicle-main text-end mb-4">
          <button
            className="btn-offer-vehicle "
            onClick={() => handleOfferVehicle()}
          >
            Offer Vehicles
          </button>
        </div>

        <div
          className=""
          style={{
            width: "100",
            overflow: "auto",
            height: "50vh",
            marginBottom: "60px",
          }}
        >
          <table className="  ">
            <thead>
              <tr>
                <th>
                  <label>S.No</label>
                </th>
                <th>
                  <label>Reference Number</label>
                </th>

                {/* <th>
                  <label>Vehicle Type</label>
                </th> */}
                <th>
                  <label>Model</label>
                </th>
                <th>
                  <label>Power Plate No. & Type</label>
                </th>
                <th>
                  <label>Trailer Plate No. & Type</label>
                </th>
                <th>
                  <label>Driver Name</label>
                </th>
                <th>
                  <label></label>
                </th>
              </tr>
            </thead>
            <tbody>
              {addedVehicleList.map((vehicle, index) => {
                return (
                  <tr className="Rowbgcolor " key={index + "vehicle"}>
                    <td>{index + 1}</td>
                    <td id="Owner">{Load.load_reference_no} </td>
                    {/* <td id="Vehicle">{vehicle.owner_name}</td> */}
                    {/* <td id="Model">{vehicle.vehicle_type}</td> */}
                    <td id="Plate">{vehicle.vehicle_model_no}</td>
                    <td id="DriverNAM">
                      {vehicle.plate_number}
                      {"-"}
                      {vehicle.vehicle_type}
                    </td>
                    <td id="DriverNAM">
                      {vehicle.trailer_plate_no}
                      {"-"}
                      {vehicle.trailer_vehicle_type}
                    </td>
                    <td id="Nationality">{vehicle.driver_name}</td>
                    <td>
                      <div className="option-dropdown dropdown p-2">
                        <a
                          href=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ic_options} alt="" />
                        </a>
                        <ul className="dropdown-menu ">
                          <li>
                            <Link
                              to={
                                "/VehicleDriverDetailsHeader/" +
                                vehicle.vehicle_id
                              }
                              state={{
                                id: vehicle.vehicle_id,
                                transporter_id: vehicle.owner_id,
                                driver_details: vehicle.driver_details,
                              }}
                              className="text-decoration-none"
                            >
                              <button className="dropdown-item" type="button">
                                View Details
                              </button>
                            </Link>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => {
                                deleteVehicleOffer(
                                  vehicle?.trip_vehicle_id,
                                  vehicle?.bid_vehicle_id
                                );
                              }}
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="fix-offer-vehicles" style={{ overflow: "auto" }}>
          <div className="row p-4  mb-4" style={{ minWidth: "1250px" }}>
            <div className="col-2 ">
              <label htmlFor="">Offer Load Reference No.</label>
              <p className="data">{Load && Load.load_reference_no}</p>
            </div>
            {/* <div className="col-2 ">
              <label htmlFor="">Availability date of vehicle</label>
              <p className="data">{Load && Load.vehicle_availability_date}</p>
            </div> */}
            <div className="col-2 ">
              <label htmlFor="">Cargo Type</label>
              <p className="data">{Load && Load.cargo_type}</p>
            </div>
            <div className="col-2 ">
              <label htmlFor="">Quantity</label>
              <p className="data">{Load && Load.quantity}</p>
            </div>
            {/* <div className="col-2 ">
              <label htmlFor="">Number of Trucks</label>
              <p>
                <span className="data m-0">{Load && Load.truck_count}</span>{" "}
              </p>
            </div> */}
          </div>
          <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
            <div className="left"></div>
            <div className="right">
              <a href="#">
                <button type="cancel" className="cancel-btn">
                  Cancel
                </button>
              </a>
              <Link to="#" onClick={sendVehicleOffer}>
                <button type="submit" className="continue-btn">
                  Send Offer
                </button>
              </Link>
            </div>
          </div>
        </div>
      </main>

      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
        className="offer-vehicle-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="card-heading">Offer Vehicle</h5>
          </Modal.Title>
        </Modal.Header>
        <hr />

        <Modal.Body>
          <section className="offer-vehicles ">
            <div className="row " style={{ marginBottom: "70px" }}>
              {selectVehicle?.vehicle_image && (
                <div className="col-lg-3 col-md-6 ">
                  <div className="top-item mt-2">
                    <label className="" style={{ color: "#66739B" }}>
                      Vehicle Images
                    </label>
                  </div>
                  <div className="uploaded_items d-flex mt-4">
                    {selectVehicle.vehicle_images &&
                      selectVehicle.vehicle_images.map((img) => {
                        return (
                          <div className="item_list">
                            <img
                              className="vehicle_img"
                              width="200px"
                              height={200}
                              src={
                                ApiConfig.BASE_URL_FOR_IMAGES +
                                img.vehicle_image_url
                              }
                              alt="No image "
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              <div className="col-lg-9 col-md-6">
                <div className="row">
                  <div className="col-lg-12 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Reference Number
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={Load && Load.load_reference_no}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Vehicle Type</label>
                      <select
                        className="form-select"
                        onChange={(e) => {
                          setCurrentVehicle(e);
                        }}
                      >
                        <option>Select Vehicle</option>
                        {vehicleList?.map((vehicle) => {
                          return (
                            <option
                              id={vehicle.vehicle_name_id}
                              value={vehicle.vehicle_name_value}
                            >
                              {vehicle.vehicle_name_value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Power Plate Number
                      </label>
                      <select
                        type="search"
                        className="form-select"
                        id="exampleInputEmail1"
                        onChange={(e) => {
                          setCurrentPlateNo(e);
                        }}
                        aria-describedby="emailHelp"
                      >
                        <option>Select Vehicle</option>
                        {plateNoList?.map((vehicle) => {
                          return (
                            <option value={vehicle.vehicle_id}>
                              {" "}
                              {/* {vehicle.plate_number}
                              {"-"} */}
                              {vehicle.plate_number}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Model</label>
                      <input
                        type="text"
                        disabled
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle?.vehicle_model_no
                            ? selectVehicle?.vehicle_model_no
                            : ""
                        }
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Year of Manufacture
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.year_manufacture
                            ? selectVehicle.year_manufacture
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Chassis Number</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.vehicle_chassis_no
                            ? selectVehicle.vehicle_chassis_no
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Motor Number</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.vehicle_motor_no
                            ? selectVehicle.vehicle_motor_no
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Gross Weight</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.vehicle_gross_weight
                            ? selectVehicle.vehicle_gross_weight
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Vehicle Load Capacity in Quintal
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.vehicle_capacity
                            ? selectVehicle.vehicle_capacity
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Current km</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.vehicle_capacity
                            ? selectVehicle.vehicle_capacity
                            : ""
                        }
                      />
                    </div>
                  </div>
                  {selectedVehicleType === "Power" ? (
                    <></>
                  ) : (
                    <>
                      <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Trailer Plate Number
                          </label>
                          <select
                            type="search"
                            className="form-select"
                            id="exampleInputEmail1"
                            onChange={(e) => {
                              setCurrenttrailer(e);
                            }}
                            aria-describedby="emailHelp"
                          >
                            <option>Select Vehicle</option>
                            {trailerList?.map((vehicle) => {
                              return (
                                <option value={vehicle.vehicle_id}>
                                  {" "}
                                  {vehicle.plate_number}
                                  {"-"}
                                  {vehicle.vehicle_type}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">Model</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={selectedtrailer?.vehicle_model_no}
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-4">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Vehicle Type
                          </label>
                          <input
                            disabled
                            className="form-control"
                            aria-label="Default select example"
                            value={
                              selectedtrailer?.vehicle_type
                                ? selectedtrailer?.vehicle_type
                                : ""
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Year of Manufacture
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={selectedtrailer?.year_manufacture}
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Chassis Number
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={
                              selectedtrailer?.vehicle_chassis_no
                                ? selectedtrailer?.vehicle_chassis_no
                                : ""
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Motor Number
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={selectedtrailer?.vehicle_motor_no}
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Gross Weight
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={
                              selectedtrailer?.vehicle_gross_weight
                                ? selectedtrailer?.vehicle_gross_weight
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Vehicle Load Capacity in Quintal
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={
                              selectedtrailer?.vehicle_capacity
                                ? selectedtrailer?.vehicle_capacity
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">Current km</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={
                              selectedtrailer?.vehicle_capacity
                                ? selectedtrailer?.vehicle_capacity
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-lg-6 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Driver and Licence Number
                      </label>
                      <select
                        type="search"
                        className="form-select"
                        id="exampleInputEmail1"
                        onChange={(e) => {
                          setCurrentDriver(e);
                        }}
                        aria-describedby="emailHelp"
                      >
                        <option value="" selected>
                          Select Driver
                        </option>

                        {driverList &&
                          driverList.length &&
                          driverList.map((driver) => {
                            return (
                              <option value={driver.driver_id}>
                                {" "}
                                {driver.driver_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Driver Driving License
                      </label>
                      <input
                        disabled
                        type="search"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedDriver && selectedDriver.licence_number}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
            <div className="left"></div>
            <div className="right">
              <a href="#">
                <button
                  type="submit"
                  className="cancel-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(false);
                  }}
                >
                  Cancel
                </button>
              </a>
              <Link to="#">
                <button
                  type="submit"
                  className="continue-btn"
                  onClick={addVehicleOffer}
                  // disabled={vehicleDetails?.driver_id ? false : true}
                >
                  Add Vehicle
                </button>
              </Link>
              {/* <div className="login-btn">
                <button
                  type="submit"
                  
                  className="btn  col-sm-12 col-md-12 col-lg-12"
                >
                  <span style={{ color: "white" }}> Continue</span>
                </button>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OfferVehicles;
