import React, { useState, useEffect, useContext } from "react";
import eyes from "../../assets/images/eyes.svg";
import { AppContext } from "../../context/user/AppContext";
import Skyscrapers from "../../assets/images/Skyscrapers.jpg";
import { Modal, Button } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
const OfferedVehiclesViewDetails = () => {
  const { mainClass, customerData } = useContext(AppContext);
  const prams = useParams();
  const id = prams.id;
  const offeredVehicle = useLocation().state;
  console.log("offeredVehicle", offeredVehicle);
  const [offeredVehicleDetails, setOfferedVehicleDetails] = useState(
    offeredVehicle ? offeredVehicle : {}
  );
  const [vehicleList, setVehicleList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [current, setCurrent] = useState({});
  console.log(current);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_LIST_BY_LOADID,
      JSON.stringify({ ...customerData, load_id: id })
    )
      .then((res) => {
        console.log("res", res);
        setLoading(false);
        if (res.json.result) setVehicleList(res.json.vehicle_list);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
    return () => {
      setOfferedVehicleDetails({});
    };
  }, []);

  return (
    <main className={"main-offerVehicles " + mainClass}>
      <div
        style={{
          width: "100",
          overflow: "auto",
          height: "50vh",
          marginBottom: "60px",
        }}
      >
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <table className="  ">
            <thead>
              <tr>
                <th>
                  <label>S.No</label>
                </th>
                <th>
                  <label>Reference Number</label>
                </th>
                <th>
                  <label>Owner Name</label>
                </th>
                <th>
                  <label>Vehicle Type</label>
                </th>
                <th>
                  <label>Model</label>
                </th>
                <th>
                  <label>Plate No.</label>
                </th>
                {/* <th>
                <label>Driver Name</label>
              </th> */}
                <th>
                  <label></label>
                </th>
              </tr>
            </thead>
            <tbody>
              {vehicleList.map((vehicle, index) => {
                console.log(vehicle);
                return (
                  <tr className="Rowbgcolor ">
                    <td>{index + 1}</td>
                    <td id="Owner"> {vehicle.reference_no}</td>
                    <td id="Vehicle">{vehicle.vehicle_owner}</td>
                    <td id="Model">{vehicle.vehicle_type}</td>
                    <td id="Plate">{vehicle.vehicle_model_no}</td>
                    <td id="DriverNAM">{vehicle.plate_number}</td>
                    {/* <td id="Nationality">{vehicle.driver_name}</td> */}
                    <td>
                      <div className="option-dropdown dropdown p-2">
                        <a
                          href=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => {
                            setCurrent(vehicle);
                            handleShow();
                          }}
                        >
                          <img src={eyes} alt="" />
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id=""
        className="Vehicle-popup-offered"
      >
        <Modal.Header closeButton>
          <h4 className="card-heading">Vehicle Details</h4>
        </Modal.Header>
        <div>
          <hr />
          <section
            className="vehicle-detail-section mb-0 common-body p-3"
            style={{ height: "80vh", overflow: "auto" }}
          >
            <div className="row">
              <div className="col-lg-6">
                <div className="image">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      {current &&
                        current.vehicle_images &&
                        current.vehicle_images.map((img, index) => {
                          console.log("img", img)
                          return (
                            <div
                              className={
                                index === 0
                                  ? "carousel-item active"
                                  : "carousel-item"
                              }
                              key={img.vehicle_image_id}
                            >
                              <img
                                src={
                                  ApiConfig.BASE_URL_FOR_IMAGES +
                                  img.vehicle_image_url
                                }
                                className="d-block "
                                alt={"..."}
                                width="200px"
                                height={"200px"}
                              />
                            </div>
                          );
                        })}
                    </div>

                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="right-section">
                  <div className="right-top-content d-flex justify-content-between">
                    <div className="title-vehicle">
                      <h4 className="name-vehicle">
                        {current && current.vehicle_owner}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 left item mt-2">
                      <label>Plate Number</label>
                      <p className="data">{current && current.plate_number}</p>
                    </div>
                    <div className="col-lg-4 middel item mt-2">
                      <label>Vehicle Type</label>
                      <p className="data">{current && current.vehicle_type}</p>
                    </div>
                    <div className=" col-lg-4 right item mt-2">
                      <label>Year of Manufacture</label>
                      <p className="data">{current && current.vehicle_type}</p>
                    </div>
                    <div className="col-lg-4 left item mt-2">
                      <label>Chassis Number</label>
                      <p className="data">
                        {current && current.vehicle_chassis_no}
                      </p>
                    </div>
                    <div className="col-lg-4 middel item mt-2">
                      <label>Motor Number</label>
                      <p className="data">
                        {current && current.vehicle_motor_no}
                      </p>
                    </div>
                    <div className="col-lg-4 right item mt-2">
                      <label>Gross Weight</label>
                      <p className="data">
                        {current && current.vehicle_gross_weight}
                      </p>
                    </div>
                    <div className="col-lg-4 left item mt-2">
                      <label>Vehicle Load Capacity</label>
                      <p className="data">
                        {current && current.vehicle_load_capacity} Quintals
                      </p>
                    </div>

                    <div className="col-lg-4 right item mt-2">
                      <label>Initial km</label>
                      <p className="data">
                        {current && current.vehicle_initial_km}
                      </p>
                    </div>
                    <h5 className="card-heading mt-2">GPS Availability</h5>
                    <div className="col-lg-4 left item mt-2">
                      <label>Vendor Name</label>
                      <p className="data">
                        {current && current.vehicle_vendor_name}
                      </p>
                    </div>
                    <div className="col-lg-4 right item mt-2">
                      <label>Vendor Contact</label>
                      <p className="data">
                        {current && current.vehicle_vendor_contact}
                      </p>
                    </div>
                    <div className="col-lg-4 right item mt-2">
                      <label>Vendor Platform</label>
                      <p className="data">
                        {current && current.vehicle_vendor_platform}
                      </p>
                    </div>
                    <div className="col-lg-4 right item mt-2">
                      <label>Vendor Address</label>
                      <p className="data">
                        {current && current.vehicle_vendor_address}
                      </p>
                    </div>
                    <h5 className="card-heading mt-2">Driver Details</h5>
                    <div className="col-lg-4 left item mt-2">
                      <label>Name</label>
                      <p className="data">{current && current.driver_name}</p>
                    </div>
                    <div className="col-lg-4 left item mt-2">
                      <label>Nationality</label>
                      <p className="data">{current && current.nationality}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Modal>
    </main>
  );
};

export default OfferedVehiclesViewDetails;
