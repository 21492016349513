import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import "../assets/css/Driverlist.scss";
import eyes from "../assets/images/eyes.svg";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { AppContext } from "../context/user/AppContext";
import Loader from "./Loader";
const OrderConfirmation = () => {
  const [data, setData] = useState([]);
  let currentRoute = useLocation().pathname;
  const [progress, setProgress] = useState(false);
  const { customerData, mainClass } = useContext(AppContext);
  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
  });
  useEffect(() => {
    setProgress(true);

    postWithAuthCallWithErrorResponse(
      currentRoute == "/OrderConfirmation"
        ? ApiConfig.DIRECT_ORDERS_ORDER_CONFIRMATION_
        : ApiConfig.DIRECT_ORDERS_ORDER_CONFIRMATION_Online_,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        setProgress(false);
        console.log("data", res);
        setData(res.json.load_list);
      })
      .catch((err) => console.log("err", err));
  }, []);
  return (
    <React.Fragment>
      <div className={"vehicle " + mainClass} id="cxMain">
        <div className="row">
          <div className="col-lg-12 col-md-12 ">
            <div className="d-flex  ">
              <div className="input-group   ">
                <div className="form-outline-md searchBox  "></div>
                <div className="PageSelect d-flex justify-content-center "></div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <div style={{ width: "100%", overflow: "auto" }}>
                {progress ? (
                  <Loader color={"blue"} />
                ) : (
                  <table
                    className="table-striped  datalist"
                    style={{ minWidth: "1400px" }}
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Reference Number</th>
                        <th>Operation Number</th>
                        <th>Transporter</th>
                        <th>Shipper</th>
                        <th>Unit Price</th>
                        <th>Total Price </th>
                        <th>Loading Place</th>
                        <th>Expected Loading Time</th>
                        <th>Received On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.length &&
                        data.map((dat) => {
                          return (
                            <tr className="table-row mb-3">
                              <td>1</td>
                              <td id="">{dat.trip_reference_no} </td>
                              <td id="">{dat.trip_operation_no}</td>
                              <td id="">{dat.transporter}</td>
                              <td id="">{dat.shipper}</td>
                              <td id="">{dat.unit_price}</td>
                              <td id="">{dat.total_price}</td>
                              <td id="">{dat.loading_place}</td>
                              <td id="">
                                {dat.trip_start_date +
                                  "," +
                                  dat.trip_start_time}
                              </td>
                              <td id="">{dat.received_on}</td>

                              <div className="option-dropdown dropdown p-2">
                                <Link
                                  to="/ViewOrder"
                                  className="text-decoration-none"
                                  state={dat}
                                >
                                  <button
                                    className="dropdown-item "
                                    type="button"
                                  >
                                    <img src={eyes} alt="" />
                                  </button>
                                </Link>
                              </div>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderConfirmation;
