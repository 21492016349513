import React from "react";
import { Link, useLocation } from "react-router-dom";
import AbhayHeader from "../../sharedComponent/AbhayHeader";
import Sidebar from "../../sharedComponent/Sidebar";
import DriverDetails from "../Driver/DriverDetails";
import VehiclesDetails from "./VehiclesDetails";
// import '../../assets/css/VehicleDriverDetailsHeader.scss'

const VehicleDriverDetailsHeader = () => {
  return (
    <div>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <main className=" ">
        <div className="header-body cx-active-sidebarB" id="cxMainB">
          <ul className="nav nav-pills mb-1" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <Link
                to="/VehiclesDetails"
                className="nav-link tab-link active"
                id="pills-driverdetails-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-driverdetails"
                type="button"
                role="tab"
                aria-controls="pills-driverdetails"
                aria-selected="true"
              >
                Vehicle Details
              </Link>
            </li>

            <li className="nav-item" role="presentation">
              <Link
                to="/DriverDetails"
                className="nav-link tab-link"
                id="pills-customerdetails-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-customerdetails"
                type="button"
                role="tab"
                aria-controls="pills-customerdetails"
                aria-selected="false"
              >
                Driver Details
              </Link>
            </li>
          </ul>
          <div
            className="tab-content "
            id="pills-tabContent"
            style={{ marginBottom: "80px", overflow: "auto" }}
          >
            <div
              className="tab-pane fade show active"
              id="pills-driverdetails"
              role="tabpanel"
              aria-labelledby="pills-driverdetails-tab"
            >
              <VehiclesDetails />
            </div>

            <div
              className="tab-pane fade"
              id="pills-customerdetails"
              role="tabpanel"
              aria-labelledby="pills-customerdetails-tab"
            >
              <DriverDetails />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default VehicleDriverDetailsHeader;
