import React, { useEffect } from 'react';
import ApiConfig from '../api/ApiConfig';
import ic_upload_document from "../assets/images/AddVehicles/ic_upload_document.svg";
export default function DragAndDropForDrivers({ setFile, license_file }) {
    let id = "DropZone"
    useEffect(() => {
        initDragAndDrop();
    }, [])
    const initDragAndDrop = () => {
        var dropZone = document.getElementById(id);
        // Optional.   Show the copy icon when dragging over.  Seems to only work for chrome.
        dropZone && dropZone.addEventListener('dragover', function (e) {
            e.stopPropagation();
            e.preventDefault();
            e.dataTransfer.dropEffect = 'copy';
        });
        dropZone && dropZone.addEventListener('drop', drop);
    }
    function drop(e) {
        e.stopPropagation();
        e.preventDefault();
        let file = "";
        if (e.target && e.target.files) {
            file = e.target.files[0];
        } else {
            file = e.dataTransfer.files[0]
        }
        setFile(file)

    }

    return (
        <div className="border-block " id={id}>
            <div className="top-item">
                <label style={{ color: "#66739B" }}>
                    Upload License
                </label>
                <div className="border-block mt-4">
                    <div className="uploading ">
                        <img src={license_file && license_file.name ? URL.createObjectURL(license_file) : license_file && license_file.length ? ApiConfig.BASE_URL_FOR_IMAGES + license_file : ic_upload_document} alt="" width={100} height={100} />
                        <p className="m-0 mt-3 drag-drop">
                            Drag & Drop files to upload
                        </p>
                        <p className="drag-drop">OR</p>
                        <a href="#" style={{ color: "#4D9DE7" }} onClick={(e) => {
                            e.preventDefault();
                            document.getElementById("uploadVehicleImages").click()
                        }}>
                            Browse Files
                        </a>
                    </div>
                </div>
            </div>
            <input onChange={drop} accept="image/png, image/gif, image/jpeg, application/pdf" id={"uploadVehicleImages"} type="file" name="vehicle_images" style={{ display: "none" }} />


        </div>
    )
}
